@import "./vars";

body {
    font-family: $font_text;
    background: $bg;
   // background: coral;
    color: $black;
    line-height: 1.25;
}

/*.modalisimo {
    .modal-content {
        max-width: 450px;
        margin: 0 auto;
    }

    .modal_alert {
        position: relative;
        padding: 2rem;
        padding-bottom: 1rem;

        .btn_closemodal {
            cursor: pointer;
            border: 1px solid #dce3e8 !important;
            background: #ffffff;
            width: 30px;
            height: 30px;
            position: absolute;
            padding-top: 5px;
            top: -15px;
            right: 25px;
            border-radius: 0.4rem;
            text-align: center;
            box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.15);
        }
    }
}*/

.modalisimo {
  .modal-content {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
  }

  .modal_alert {
    position: relative;
    padding: 2rem;
    padding-bottom: 1rem;

    p.paragraph {
      font-size: 1.2rem;
    }

    .l-spa {
      font-weight: 700;
      letter-spacing: 1px;
    }

    .btn_closemodal {
      cursor: pointer;
      border: 1px solid #dce3e8 !important;
      background: #ffffff;
      width: 30px;
      height: 30px;
      position: absolute;
      padding-top: 5px;
      top: -15px;
      right: 25px;
      border-radius: 0.4rem;
      text-align: center;
      box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.15);
    }
  }
}

// Sweet alert ---
.swal-modal .swal-text {
  text-align: center;
}

// WEIGHT SYSTEMS ---
.fw200 {
  font-weight: 200 !important;
}

.fw300 {
  font-weight: 300 !important;
}

.fw500 {
  font-weight: 500 !important;
}

.fw600 {
  font-weight: 600 !important;
}

.fw700 {
  font-weight: 700 !important;
}

.fw800 {
  font-weight: 800 !important;
}

.fw900 {
  font-weight: 900 !important;
}

a {
  color: $black;

  &:hover {
    text-decoration: none;
    color: $primary;
  }
}

img {
  max-width: 100%;
}

.cursor {
  cursor: pointer;
}

hr {
  border-color: #ffffff;
}

.trans {
  -webkit-transition: 0.5s cubic-bezier(0.2, 0.61, 0.41, 0.95);
  transition: 0.5s cubic-bezier(0.2, 0.61, 0.41, 0.95);
}

.box_flex {
  display: flex;

  &.baseline {
    align-items: baseline;
  }

  &.justify_center {
    justify-content: center;
  }

  &.justify_between {
    justify-content: space-between;
  }

  &.justify_around {
    justify-content: space-around;
  }

  &.align_center {
    align-items: center;
  }
}

.ttls {
  font-family: $font_ttls;
}

.c_primary {
  color: #d82d28;
}

.c_green {
  color: #2eab2e;
}

.c_white {
  color: #fff;
}

.btn {
  font-family: $font_ttls;
  display: inline-block;
  vertical-align: middle;
  background: $primary;
  height: 35px;
  color: #ffffff;
  border-radius: 0.5rem;
  padding: 0.25rem 1rem;
  min-width: 115px;
  font-weight: 500;
  border: 2px solid #ff716c;
  cursor: pointer;
  -webkit-transition: 0.5s cubic-bezier(0.2, 0.61, 0.41, 0.95);
  transition: 0.5s cubic-bezier(0.2, 0.61, 0.41, 0.95);
  outline: none;

  &:active,
  &:hover,
  &:focus {
    outline: none;
    box-shadow: none;
    background: $primary_hover;
    color: #ffffff;
  }

  &.btn_border {
    background: transparent;
    border: 2px solid $primary;
    color: $primary;

    &:hover {
      background: $primary;
      color: #ffffff;
    }
  }

  &.btn_border_clrs {
    background: transparent;
    border: 2px solid #ffffff;
    color: #ffffff;
    font-weight: 500;

    &:hover {
      background: #ffffff;
      color: $primary;
    }
  }
}

.form-group {
  & > label {
    font-size: 0.9rem;
    margin-bottom: 0.25rem;
  }

  .form-control {
    background: $bg;
    border-radius: 0.35rem;
  }
}

.hg_top {
  margin-top: 115px !important;
}

.ptop {
  padding-top: 15%;

  &.min {
    padding-top: 10% !important;
  }
}

.bg_logo_shop {
  width: 75px;
  height: 75px;
  margin: 0 auto;
  display: flex;
  justify-self: center;
  overflow: hidden;
  align-items: center;
  border-radius: 50%;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: $primary;
  border-color: $primary;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: $primary;
  background-color: $primary;
}

.bg_sections {
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat;
  position: absolute;
  top: 70px;
  left: 0;
  min-height: 300px;
  width: 100%;
  z-index: -1;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(5, 14, 38, 0.25);
    background: -moz-linear-gradient(
      top,
      rgba(5, 14, 38, 0.25) 0%,
      rgba(5, 14, 38, 0.95) 100%
    );
    background: -webkit-gradient(
      left top,
      left bottom,
      color-stop(0%, rgba(5, 14, 38, 0.25)),
      color-stop(100%, rgba(5, 14, 38, 0.95))
    );
    background: -webkit-linear-gradient(
      top,
      rgba(5, 14, 38, 0.25) 0%,
      rgba(5, 14, 38, 0.95) 100%
    );
    background: -o-linear-gradient(
      top,
      rgba(5, 14, 38, 0.25) 0%,
      rgba(5, 14, 38, 0.95) 100%
    );
    background: -ms-linear-gradient(
      top,
      rgba(5, 14, 38, 0.25) 0%,
      rgba(5, 14, 38, 0.95) 100%
    );
    background: linear-gradient(
      to bottom,
      rgba(5, 14, 38, 0.25) 0%,
      rgba(5, 14, 38, 0.95) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#050e26', endColorstr='#050e26', GradientType=0 );
  }
}

.wrapper_scroll {
  /* width */
  &::-webkit-scrollbar {
    height: 6px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background: #eeeeee;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $primary;
  }
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $primary_hover;
  }
}

.wrapper_main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}

.wrapper_welcome {
  font-family: $font_ttls;
  text-align: center;
  margin-top: 5rem;
  margin-bottom: 3rem;
}

.drawer__hub {
  display: flex;
  margin-bottom: 5rem;
  justify-content: space-between;
}

.ads_horizontal {
  background: #e2e5e9;
  max-width: 880px;
  max-height: 100px;
  margin: 0 auto;
  margin-bottom: 4.25rem;
  a {
    display: block;

    img {
      width: 100%;
    }
  }
}

.ads_vertical {
  margin-left: 2.25rem;
  position: relative;

  .adsbygoogle,
  a {
    position: sticky;
    max-width: 250px;
    min-width: 250px;
    top: 100px;
    display: block;

    img {
      width: 100%;
      height: auto;

      &.b-mobile {
        display: none;
      }
    }
  }
}

.wrapper_hub_container {
  margin-bottom: 2rem;
  width: 100%;

  .ttls_hub {
    position: relative;

    h4.ttls {
      background: $bg;
      display: inline-block;
      position: relative;
      z-index: 1;
      padding-right: 1rem;

      .ico_hub {
        img {
          height: 35px;
          margin-right: 0.5rem;
        }
      }
    }

    &:after {
      content: "";
      height: 1px;
      width: 100%;
      background: #cccccc;
      position: absolute;
      top: 22px;
      left: 0;
    }
  }

  .nav-tabs {
    border: 0;
    margin-bottom: 1.75rem;

    .nav-item {
      margin-bottom: 0.2rem;

      .nav-link {
        font-family: "Poppins", sans-serif;
        font-weight: 500;
        opacity: 0.4;
        padding: 0.45rem 0;
        cursor: pointer;
        border: 0;
        margin-right: 1rem;
        color: #333;
        -webkit-transform: perspective(1px) translateZ(0);
        transform: perspective(1px) translateZ(0);
        box-shadow: 0 0 1px transparent;
        position: relative;
        -webkit-transition-property: color;
        transition-property: color;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;

        &:before {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          height: 3px;
          width: 22px;
          background: #333333;
          -webkit-transform: scaleX(0);
          transform: scaleX(0);
          -webkit-transform-origin: 0 50%;
          transform-origin: 0 50%;
          -webkit-transition-property: transform;
          transition-property: transform;
          -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
          -webkit-transition-timing-function: ease-out;
          transition-timing-function: ease-out;
        }

        &:hover:before {
          -webkit-transform: scaleX(1);
          transform: scaleX(1);
        }

        &.active {
          font-weight: 700;
          background-color: transparent;
          border: 0;
          opacity: 1;

          &:before {
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
            background: #333;
          }
        }
      }
    }
  }
}

.drawer_ttls {
  margin-bottom: 5rem;

  .ttls_hub {
    position: relative;

    .ttls {
      background: $bg;
      display: inline-block;
      position: relative;
      z-index: 1;
      padding-right: 1rem;
     // font-size: 2rem;

      .ico_lonchera {
        img {
          height: 32px;
          margin-right: 0.5rem;
        }
      }
    }

    &:after {
      content: "";
      height: 1px;
      width: 100%;
      background: #cccccc;
      position: absolute;
      top: 22px;
      left: 0;
    }
  }
}

// === SECTIONS LEGALES ===
.wrapper_legal {
  position: relative;
  margin-bottom: 3rem;

  .content_legal {
    .box_legal {
      background: #ffffff;
      padding: 1rem 1.5rem;
      border-radius: 0.75rem;
      box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.25);
      font-weight: 300;
      font-size: 0.8rem;
      text-align: justify;

      blockquote {
        margin: 0rem 1rem;
      }
    }
  }
}

.wrapper_sections {
  padding: 1rem;
  max-width: 990px;
  margin: 0 auto;

  .contain_sections {
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.15);
    background: #fff;

    &.cancel {
      background: transparent;
      box-shadow: none;
    }

    .box_info_sections {
      padding: 1.5rem 2rem;

      & > h6 {
        line-height: 1.5;
      }

      hr {
        border-color: #eeeeee;
      }
    }

    .box_picture {
      min-height: 350px;
      background-position: top center !important;
      background-size: cover !important;
      background-repeat: no-repeat;
    }

    .box_cancel {
      padding: 2rem 0;

      .cancel_label {
        font-weight: 500;
        font-size: 0.9rem;
        letter-spacing: 2px;
        color: $black;
      }

      .cancel_box_icons {
        font-size: 6rem;
        color: $primary;
        padding-top: 1rem;

        & > h1 {
          font-weight: 700;
          font-size: 3.5rem;
          font-style: italic;
          line-height: 0.75;
        }
      }

      .cancel_info {
        margin-top: 2rem;
        padding: 0 2rem;
      }
    }
  }
}

.container_provider {
  padding: 0 1.75rem;
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
}

.wrapper_data {
  background: $bg;
  max-width: 550px !important;
  padding: 1rem !important;
  position: relative;

  .btn_close_modal {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
    font-family: $font_ttls;
    font-weight: 500;
    font-size: 0.65rem;
    border: 2px solid $primary;
    padding: 0.15rem 0.25rem;
    color: $primary;
    border-radius: 0.25rem;
    cursor: pointer;

    &:hover {
      background: $primary;
      color: #ffffff;
    }
  }

  .modal-content {
    border: 1px solid #ededed;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.15);
    border-radius: 10px;

    .box_content_data {
      padding: 1.25rem 1rem;

      .box_info_data {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-bottom: 1px solid #ededed;
        padding-bottom: 1rem;
        margin-bottom: 1rem;

        .ttl_info_data {
          margin-left: 1rem;
        }
      }

      .new_ref {
        margin-bottom: 1rem;

        span {
          color: $primary;
          text-decoration: underline;
          font-size: 0.9rem;

          &:hover {
            color: $primary_hover;
          }
        }
      }
    }
  }
}

/* == Slider  == */
.slick-slider {
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: "•";
  text-align: center;
  opacity: 0.25;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: black;
}

// === paymentMethods ===
.container_payment {
  padding: 1rem;
}

​​​​​ .item_content_hubx {
  background: olive !important;

  img {
    width: 95%;
    transition: transform 0.3s;
  }
}

.wrapper_shop_details {
  padding: 10px;
  padding-top: 150px;
  .contain_shop_history {
    background: #fff;
    box-shadow: 2px 2px 26px rgba(0, 0, 0, 0.25);
    max-width: 575px;
    width: 100%;
    margin: auto;
    padding: 1.5rem;
    border-radius: 0.75rem;
    font-size: 0.9rem;
    .box-ttls-shop {
      text-align: center;
      img {
        max-height: 70px;
      }
      .ttl_shop {
        color: #2aa72a;
        margin: 1rem 0 0.5rem;
      }
      .ptext {
        max-width: 475px;
        width: 100%;
        margin: 0 auto;
        margin-top: 1rem;
        font-size: 0.9rem;
      }
    }
    hr {
      border-color: #eaeaea;
    }
    .box_status_shop {
      display: flex;
      justify-content: space-between;
      .info_status {
        text-align: center;
        width: 100%;
        span {
          display: block;
        }
      }
    }
    .box_table_details {
      margin-top: 1.25rem;
      font-size: 0.8rem;
      .table {
        thead {
          th {
            border: 0;
            background: #efefef;
          }
        }
      }
    }
  }
  .btn-light {
    color: #212529 !important;
    background-color: #f8f9fa !important;
    border-color: #d3d3d3;
  }
  .btn-link {
    font-weight: 600 !important;
    color: #333 !important;
    text-decoration: none !important;
    background: none !important;
    border: 0 !important;
    &:hover{
      color: #d82d28 !important;
    }
}
}

// === RWD ===
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 750px;
    margin: 1.75rem auto;
  }

  .container {
    max-width: 880px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 1080px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1300px;
  }
}

// ============

@media screen and (max-width: 1200px) {
}

@media screen and (max-width: 1024px) {
}

@media screen and (max-width: 990px) {
  /* .ads_vertical {
        .adsbygoogle, a {
            max-width: 180px;
            min-width: 180px;
        }
    }*/

  .box_container_hub {
    .item_provaider_hub {
      width: 33.3%;

      .item_content {
        & > img {
          height: 150px;
          transition: transform 0.3s;
        }
      }
    }
  }

  .modal-dialog {
    padding: 2rem 2rem 0;
  }

  .aside_providers {
    width: 200px;
  }

  .contain_providers {
    width: calc(100% - 245px);
    margin-left: 20px;

    .contain_info_provaider {

      .box_info_provaider {
        color: #ffffff;
        text-shadow: 2px 2px 12px rgba(0, 0, 0, 0.25);

        .ttls_provider {
          font-family: $font_ttls;
          font-weight: 600;
        }

        .info_provider {
          font-weight: 300;
          max-width: 475px;
          font-size: 0.95rem;
        }
      }

      .box_logo_provaider {
        margin-bottom: 1rem;

        & > img {
          height: 50px;
        }
      }
    }

    .wrapper_products_provider {
      background: #ffffff;
      box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.2);
      padding: 1rem 1.5rem;
      border-radius: 0.5rem;

      .content_products_provider {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
      }
    }
  }
}

@media screen and (max-width: 890px) {
  .modal.show .myModalCoup {
    left: 0;
    margin: 0;
  }

  .wrapper_item_product {
    padding: 0 5px !important;
  }
  .drawer__hub {
    &.v-mobile {
      .ads_vertical {
        a {
          min-width: 180px;
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .wrapper_header {
    .box_head_top {
      font-size: 0.75rem;
    }

    .box_head_content {
      .container_provider {
        padding: 0 !important;
        padding-left: 0 !important;
      }

      .box_options {
        .box_user,
        .logeado {
          cursor: pointer;
          border-right: 0 !important;
        }
      }
    }
  }

  .wrapper_welcome {
    margin-bottom: 3rem;

    .container {
      & > h1 {
        line-height: 1;
      }
    }
  }

  .box_nav_categories {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 30px;
      background: rgb(0, 0, 0);
      background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0.1) 0%,
        rgba(0, 0, 0, 0) 85%
      );
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 30px;
      background: rgb(0, 0, 0);
      background: linear-gradient(
        -90deg,
        rgba(0, 0, 0, 0.1) 0%,
        rgba(0, 0, 0, 0) 85%
      );
    }

    .nav-tabs {
      flex-wrap: nowrap;
      overflow: auto;
      padding: 0 1.25rem;
      position: relative;
      @include scrollbar_horizontal;

      .nav-item {
        white-space: nowrap;
        .nav-link {
          padding: 0.35rem 0;
        }
      }
    }
  }

  .box_container_hub {
    .item_provaider_hub {
      width: 50%;
    }
  }

  .wrapper_bullets {
    .box_item_bullets {
      margin: 1.5rem 0 0;
    }
  }

  .modal-dialog {
    padding: 2rem 1rem 0;
    &.wrapper_logeo {
      padding: 0 !important;
      margin: 0;
    }
  }

  .wrapper_logeo {
    .modal-content {
      height: 100vh;
      min-height: 575px;

      .btn_close_modal {
        right: 8px !important;
        top: 8px !important;
        border: 2px solid rgb(255, 255, 255);
        padding: 0.15rem 0.25rem;
        color: rgb(255, 255, 255);
      }

      .box_content_logeo {
        display: block;
        height: 100%;

        .content_info {
          max-width: 100% !important;
          min-height: auto !important;
          max-height: 250px !important;
          width: 100%;
          height: 100%;
          padding: 1.5rem 0.5rem;

          p {
            margin-bottom: 0.5rem !important;
          }

          & > * {
            max-width: 500px;
            margin: 0 auto;
          }
        }

        .content_logeo {
          &.login {
            padding-top: 4rem;
            margin-top: 0;
            height: calc(100% - 250px);
          }

          .htop {
            padding: 0;
            max-width: 500px;
            margin: 0 auto;
          }
        }
      }
    }
  }

  .wrapper_providers {
    margin-top: 160px;

    .aside_providers {
      display: none !important;
      height: 100%;
    }

    .contain_providers {
      width: 100%;
      margin-left: 0;

      .wrapper_products_provider {
        .wrapper_categ_mobile {
          display: block;

          .aside_providers {
            display: block !important;
            width: 100%;
            margin-bottom: 2.5rem;
            padding: 0;
            padding-bottom: 0.5rem;
            .wrapper_categories_provider {
              position: relative;

              &::before {
                content: "";
                top: 0;
                left: 0;
                width: 50px;
                height: calc(100% - 6px);
                z-index: 99;
                position: absolute;
                background: rgba(255, 255, 255, 1);
                background: -moz-linear-gradient(
                  left,
                  rgba(255, 255, 255, 1) 0%,
                  rgba(255, 255, 255, 0) 100%
                );
                background: -webkit-gradient(
                  left top,
                  right top,
                  color-stop(0%, rgba(255, 255, 255, 1)),
                  color-stop(100%, rgba(255, 255, 255, 0))
                );
                background: -webkit-linear-gradient(
                  left,
                  rgba(255, 255, 255, 1) 0%,
                  rgba(255, 255, 255, 0) 100%
                );
                background: -o-linear-gradient(
                  left,
                  rgba(255, 255, 255, 1) 0%,
                  rgba(255, 255, 255, 0) 100%
                );
                background: -ms-linear-gradient(
                  left,
                  rgba(255, 255, 255, 1) 0%,
                  rgba(255, 255, 255, 0) 100%
                );
                background: linear-gradient(
                  to right,
                  rgba(255, 255, 255, 1) 0%,
                  rgba(255, 255, 255, 0) 100%
                );
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=1 );
              }

              &::after {
                content: "";
                top: 0;
                right: 0;
                width: 100px;
                height: calc(100% - 6px);
                z-index: 99;
                position: absolute;
                background: rgba(255, 255, 255, 0);
                background: -moz-linear-gradient(
                  left,
                  rgba(255, 255, 255, 0) 0%,
                  rgba(255, 255, 255, 1) 100%
                );
                background: -webkit-gradient(
                  left top,
                  right top,
                  color-stop(0%, rgba(255, 255, 255, 0)),
                  color-stop(100%, rgba(255, 255, 255, 1))
                );
                background: -webkit-linear-gradient(
                  left,
                  rgba(255, 255, 255, 0) 0%,
                  rgba(255, 255, 255, 1) 100%
                );
                background: -o-linear-gradient(
                  left,
                  rgba(255, 255, 255, 0) 0%,
                  rgba(255, 255, 255, 1) 100%
                );
                background: -ms-linear-gradient(
                  left,
                  rgba(255, 255, 255, 0) 0%,
                  rgba(255, 255, 255, 1) 100%
                );
                background: linear-gradient(
                  to right,
                  rgba(255, 255, 255, 0) 0%,
                  rgba(255, 255, 255, 1) 100%
                );
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=1 );
              }

              .list-group {
                display: flex;
                justify-content: flex-start;
                flex-direction: row;
                overflow: auto;

                .item_category {
                  width: 100%;
                  min-width: 210px;
                  width: 100%;
                  text-align: center;
                }
              }
            }

            .box_video_provider {
              &:after {
                border-radius: 0;
              }

              & > img {
                border-radius: 0;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}


.alert-warning {
    position: fixed;
    width: max-content;
    bottom: 0;
    left: 16px;
    padding: .5rem 1rem;
    background: #ffd251;
    color: #3d3d3d;
    display: flex;
    align-items: center;
}

.alert-warning button.close {
    width: max-content;
    padding: 0;
    position: relative !important;
}

.alert-warning p {
    font-size: .85rem;
    color: #3d3d3d !important;
}

.alert-warning a {
    font-weight: 600;
    font-size: .85rem;
    padding-right: .5rem;
    color: #3d3d3d !important;
    text-decoration: underline;
}

@media(max-width: 1090px) {
    .alert-warning {
        width: 80%;
    }
}


@media(max-width: 992px) {

    .alert-warning p {
        font-size: .75rem;
    }

    .alert-warning a {
        font-size: .75rem;
    }
}

@media(max-width: 475px) {
    .alert-warning {
        width: 70%;
        flex-direction: column;
        display: flex;
        gap: .25rem;
        justify-content: flex-start;
        align-items: flex-start;
    }
}

@media screen and (max-width: 660px) {
    .wrapper_header {
        .box_head_content {
            .box_content_options {
                overflow: auto;
                box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
                background: #ffffff;
                border-top: 1px solid #dddddd;
                position: absolute;
                top: 80px;
                right: -100%;
                width: 300px;
                height: calc(100vh - 80px);
                flex-flow: column-reverse;
                justify-content: flex-end;

                &.is-visible {
                    right: 0 !important;
                }

                .content_opt_a,
                .content_opt_b {
                    width: 100%;
                    flex-flow: column;
                    justify-content: flex-start;
                    align-self: center;
                }

                .content_opt_a {
                    margin: 0;

                    .item_link_menu {
                        width: 100%;

                        .name_options {
                            padding-left: 1rem;
                            justify-content: flex-start;
                            border-bottom: 1px solid #efefef;

                            &:hover {
                                color: #fff;
                                background: $primary;
                            }

                            &::after {
                                position: absolute;
                                right: 20px;
                                top: 25px;
                            }

                            .span_name_op {
                                font-weight: 600;
                                font-size: 1.15rem;

                                &.news {
                                    &::before {
                                        content: "NUEVO";
                                        position: absolute;
                                        top: -12px;
                                        left: 0;
                                        font-weight: 500;
                                        font-size: 0.5rem;
                                        background: #e94242;
                                        border-radius: 0.15rem;
                                        color: #eeeeee;
                                        padding: 0.06rem 0.17rem 0.05rem !important;
                                    }
                                }
                            }
                        }
                    }
                }

                .content_opt_b {
                    .logeado {
                        width: 100%;
                        background: #ffffff;
                        border-bottom: 1px solid #efefef;
                        border-left: 0;
                        height: 70px;

                        .box_user_logeado {
                            justify-content: flex-start;
                            padding: 0 1rem;
                            padding-top: 0.8rem !important;

                            .ico_user {
                                margin-right: 0.5rem;

                                img {
                                    height: 30px !important;
                                }
                            }

                            .conten_nameuser {
                                .hello_user {
                                    font-size: 0.9rem;
                                }

                                .name_user {
                                    font-size: 1.25rem;
                                }
                            }
                        }

                        .dropdown-menu .dropdown-item {
                            font-weight: 400;
                            font-size: 1rem;
                            padding: 1.15rem 0.75rem !important;
                            color: #ffffff;
                        }
                    }
                }

                .link_options.dsktp {
                    display: none;
                }

                .box_user {
                    width: 100%;
                    justify-content: flex-start;
                    padding-left: 1.25rem;

                    .content_user {
                        .ttl_user {
                            font-size: 1.15rem;
                        }

                        .go {
                            font-size: 0.95rem;
                        }
                    }
                }
            }
        }

        .box_options_mobile {
            display: flex;
            justify-content: space-around;
            align-items: center;
            height: 50px;

            .link_options {
                height: 50px;
                padding: 0.5rem 0.35rem;

                p {
                    font-size: 0.75rem;
                }
            }

            .btn_mobile {
                min-width: 40px;
                text-align: center;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        .dropdown-menu {
            position: relative !important;
            border-radius: 0;
            background: #ffffff;
            width: 100%;
            top: 0 !important;

            &.show {
                transform: none !important;
            }

            .dropdown-item {
                color: $black;
                font-weight: 300px !important;
                padding-left: 2rem !important;
            }
        }
    }
}

@media screen and (max-width: 620px) {
  .wrapper_header {
    .box_head_top {
      .content_one {
        span:first-child {
          display: none;
        }
      }
    }
  }

  .wrapper_logeo .modal-content .box_content_logeo {
    .content_info {
      max-height: 180px !important;

      .p_info_wlcm {
        display: none;
      }
    }

    .content_logeo.login {
      padding-top: 2.5rem;
      height: calc(100% - 180px);
    }
  }

  .box_container_hub {
    &.four {
      .item_provaider_hub {
        width: 50%;
      }
    }
  }

  .wrapper_sections {
    .contain_sections {
      .box_info_sections {
        padding-right: 0;
      }
    }
  }

  .wrapper_welcome {
    margin-bottom: 1.5rem;
  }

  .wrapper_slider_home {
    .slick-slide {
      padding: 1rem 0.35rem 1.5rem;

      img {
        border-radius: 0.5rem;
        box-shadow: 0 2px 8px rgba($color: #000000, $alpha: 0.05);
      }

      .sld_mobile {
        display: none;
      }
    }

    .slick-next {
      right: 40px;
    }

    .slick-prev {
      left: 40px;
    }

    .slick-slide {
      .sld_mobile {
        display: block;
      }

      .sld_desktop {
        display: none;
      }
    }
  }

  .drawer__hub {
    &.v-mobile {
      flex-wrap: wrap;

      .ads_vertical {
        width: 100%;
        margin: 0;
        margin-top: 2rem;
        a {
          max-width: 300px;
          min-width: auto;
          width: 100%;
          margin: 0 auto;

          img {
            &.b-desktop {
              display: none;
            }

            &.b-mobile {
              display: block;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 570px) {
  .modal_availability .wrp_ttls h1 {
    font-size: 2rem;
  }

  .modal_availability .wpr_accordion {
    padding: 0 0.75rem 0;
    height: 85vh;
  }

  .modal.show .myModalCoup .modal-content .modal-body {
    max-height: 85vh;
    overflow: auto;
  }
  
  .wrapper_bullets {
    padding-top: 0;
  }

  .content_logeo {
    .sex {
      margin-bottom: 0.75rem;
    }
  }

  .box_container_hub {
    &.three,
    &.four,
    &.two {
      .item_provaider_hub {
        width: 100%;
      }
    }
  }

  .contain_providers {
    .contain_info_provaider {
      flex-wrap: wrap-reverse;
      margin-bottom: 1rem;

      .box_info_provaider {
        .ttls_provider {
          display: none;
        }

        .info_provider {
          padding: 0;
          max-width: 100%;
        }
      }

      .box_logo_provaider {
        text-align: left !important;
        margin-bottom: 0.5rem;

        img {
          height: 45px;
        }
      }
    }
  }

  .container_provider {
    padding: 0 0.75rem;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;

    .wrapper_products_provider {
      background: #ffffff;
      box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.2);
      padding: 0.5rem;
      border-radius: 0.5rem;
    }
  }

  .wrapper_providers {
    .contain_providers {
      .wrapper_products_provider {
        .wrapper_categ_mobile {
          .aside_providers {
            padding: 0;

            .wrapper_categories_provider {
              padding: 0 0.5rem;
              padding-bottom: 1rem;
            }

            .box_video_provider {
              & > img {
                border-radius: 0.5rem 0.5rem 0 0;
              }

              &:after {
                border-radius: 0.5rem 0.5rem 0 0;
              }
            }
          }
        }
      }
    }
  }

  .boxtop {
    margin-top: 1.5rem;
  }

  .wrapper_purchased_item {
    .body_purchased_item {
      .info_item_shopping {
        .content_item_shopping {
          border: 0;

          .item_purchased {
            padding: 0.5rem 1rem 0.25rem;
          }
        }
      }

      .action_item_shopping {
        border-top: 1px solid #dddddd;
        padding: 2rem 0.5rem !important;
      }
    }
  }

  .box_container_hub {
    .item_provaider_hub {
      .item_content {
        // padding: 4.5rem 1rem;
        & > img {
          height: 160px;
        }
      }
    }
  }
}

@media screen and (max-width: 520px) {
  .wrapper_header {
    background: #ffffff;

    .box_head_content {
      .box_brand_logo {
        img {
          height: 48px;
        }
      }

      .box_options {
        .link_options {
          height: 50px;

          .text_link {
            display: none;
          }

          &.box_user {
            .ico_user {
              margin-left: 0 !important;
            }
          }
        }
      }
    }

    .ico_user {
      img {
        height: 20px;
        margin: 0 !important;
      }
    }

    .box_user_logeado {
      padding-top: 0.35rem !important;
    }
  }

  .wrapper_sections .contain_sections {
    .col-6 {
      flex: none;
      max-width: 100%;
    }

    .box_info_sections {
      padding: 1.5rem 2rem;
    }
  }

  .wrapper_purchased_item {
    .head_purchased_item {
      .col_unic,
      .col {
        min-width: 100%;
        margin-bottom: 0.15rem;
        text-align: left;
        display: flex;
        align-items: center;

        &.first_data {
          display: inline;
        }

        .ttl_info {
          margin-right: 0.5rem;
        }
      }

      .col_unic {
        margin-top: 0.5rem;
      }
    }
  }

  .wrapper_favs_check {
    top: 93px;
  }

  .drawer__hub{
    margin-bottom: 2rem;
  }
}

@media screen and (max-width: 440px) {
  .wrapper_header {
    .box_head_top {
      .content_two {
        span:first-child {
          font-weight: 400;
          font-size: .80rem;
        }
      }

      .content_two {
        span{margin: 0 2px;}
        .fab {
          font-size: 1.2rem !important;
        }
      }
    }
  }

  .wrapper_provider_individual,
  .wrapper_itemPromoHome,
  .wrapper_hub_container {
    margin-bottom: 3rem;
  }

  .modal-dialog {
    padding: 1rem 0.5rem 0;
  }

  .wrapper_providers
    .contain_providers
    .wrapper_products_provider
    .wrapper_categ_mobile
    .aside_providers
    .wrapper_categories_provider
    .list-group
    .item_category {
    width: 100%;
    min-width: 190px;
    width: 100%;
    text-align: center;
  }

	.wrapper_recharge .sec_recharge .drawer_recharge .cont_recharge_form {
		padding: 1rem 1.25rem;
		.box_montos .montos .inputGroup {
			width: 30%;
		}
	}

  .wrapper_hub_container .ttls_hub h4.ttls,
  .drawer_ttls .ttls_hub .ttls {
    font-size: 1.25rem;
  }


}

@media screen and (max-width: 390px) {
  .wrapper_header {
    .box_head_top {
      font-size: 0.85rem;
    }

    .box_options_mobile {
      .link_options {
        justify-content: space-between;
        span {
          i {
            font-size: 16px;
          }
        }
        p {
          font-size: 0.65rem;
          text-align: center;
          line-height: .65rem;
        }
      }

      .btn_mobile {
        min-width: 40px;
        text-align: center;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .box_head_content {
      & > .container {
        padding: 0 5px;
      }

      .box_options {
        .link_options.home {
          display: none;
        }
      }

      .box_content_options {
        width: 100%;
      }
    }
  }

  .box_item_bullets {
    flex: auto;
    max-width: 100%;
  }

  .wrapper_logeo .modal-content .box_content_logeo .content_info p.welcome {
    display: none;
  }

  .box_container_hub {
    .item_provaider_hub {
      //width: 100%;

      .item_content {
        padding: 1rem 0.25rem;
        height: 100%;

        & > img {
          height: 190px;
        }
      }
    }
  }
	.wrapper_recharge .sec_recharge .drawer_recharge .cont_recharge_form {
		.box_montos .montos .inputGroup {
			width: 45%;
		}
	}
}

@media screen and (max-width: 340px) {
  .wrapper_item_product {
    flex: none;
    max-width: 100%;
  }

  .box_info_data {
    flex-direction: column;
    align-items: end !important;

    & > div:first-child {
      margin-bottom: 0.5rem;
    }
  }
}
