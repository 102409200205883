.wrapper_recharge {
  margin-bottom: 2rem;
  .sec_recharge {
    .bg_providers_recharge {
      background-size: cover !important;
      background-position: 50% !important;
      background-repeat: no-repeat;
      position: absolute;
      top: 80px;
      left: 0;
      min-height: 215px;
      width: 100%;
      z-index: -1;
    }

    .drawer_recharge {
      margin: 0 auto;
      margin-top: 150px;
      max-width: 550px;
      width: 100%;
      padding: 0 0.75rem;

      .cont_ttls {
        color: #ffffff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.4rem;
        padding: 0 0.5rem;

        .box_ttls {
          max-width: 175px;
        }

        .box_brand {
          min-width: 140px;
          margin-left: .75rem;
          text-align: right;
          img{
            max-height: 40px;
          }
        }
      }

      .cont_recharge_form {
        background: #ffffff;
        padding: 1.5rem 2rem;
        border-radius: 10px;
        box-shadow: 0 0 40px rgba(0, 0, 0, 0.15);

        .btn-form {
          background: #f0f5fa;
          border: 1px solid #e7ecf1;
          font-family: "Roboto", sans-serif;
          border-radius: 0.25rem;
          font-size: 0.75rem;
          letter-spacing: 0.25px;
          padding: 0.35rem 0.5rem;
          cursor: pointer;
          &:hover {
            background: #e7ecf1;
          }
        }

        .form-control {
          font-size: 0.9rem;
        }

        .recharge_ttl {
          .dots_num {
            background: #333;
            width: 21px;
            height: 21px;
            color: #ffffff;
            margin-right: 0.25rem;
            border-radius: 50%;
            font-size: 0.8rem;
            &.check{
              background: #2eab2e;
            }
          }
        }

        .box_select_ref {
          .box_btn {
            text-align: right;
          }
        }

        .fieldset_ref_new {
          min-width: auto;
          padding: 0.5rem 1.25rem;
          margin: 0;
          border: 1px solid #c7cdd1;
          border-radius: 10px;
          position: relative;
          .btn_close_ref_new {
            position: absolute;
            width: 18px;
            height: 18px;
            background: #939393;
            color: #fff;
            top: -20px;
            right: -6px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 0.65rem;
            cursor: pointer;

            &:hover {
              background: #e94242;
            }
          }
          legend {
            display: inline;
            width: auto;
            padding: 0.25rem 0.5rem;
            font-size: 0.85rem;
            margin-bottom: 0;
          }
          .form_name_ref {
            display: none;
            &.isShow {
              display: block;
            }
          }
        }
        .box_montos {
          margin-top: 1.5rem;
          .montos {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            .inputGroup {
              background-color: #f0f5fa;
              display: block;
              margin: 0 8px 10px 0;
              color: #999;
              position: relative;
              border-radius: 0.5rem;
              width: 23%;
              &:hover {
                label {
                  padding-top: 8px;
                }
                .btn_restric {
                  height: 18px;
                  padding-top: 2px;
                  span {
                    color: #3c454c;
                    opacity: 1;
                  }
                }
              }
              label {
                -webkit-transition: all 0.4s ease-out;
                transition: all 0.4s ease-out;
                height: 52px;
                padding-top: 16px;
                width: 100%;
                display: block;
                text-align: center;
                color: #3c454c;
                cursor: pointer;
                position: relative;
                z-index: 2;
                overflow: hidden;
                margin: 0;
                border-radius: 0.5rem;
                border: 2px solid #dce3e8;
                &:before {
                  width: 10px;
                  height: 10px;
                  border-radius: 50%;
                  content: "";
                  background-color: #d93d39;
                  position: absolute;
                  left: 50%;
                  top: 50%;
                  transform: translate(-50%, -50%) scale3d(1, 1, 1);
                  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
                  opacity: 0;
                  z-index: -1;
                }
              }
              input:checked ~ label {
                color: #ffffff;
                border: 2px solid #aa1410;

                &:before {
                  transform: translate(-50%, -50%) scale3d(56, 56, 1);
                  opacity: 1;
                }
              }
              input:checked ~ .btn_restric {
                background: #aa1410;

                span {
                  color: #ece0df;
                }
              }
              input {
                order: 1;
                z-index: 2;
                position: absolute;
                cursor: pointer;
                visibility: hidden;
              }
              .btn_restric {
                background: #dce3e8;
                text-align: center;
                position: absolute;
                left: 0;
                bottom: 0;
                height: 0;
                padding: 0;
                color: #3c454c;
                z-index: 2;
                width: 100%;
                border-radius: 0 0 5px 5px;
                font-size: 0.7rem;
                font-weight: 500;
                -webkit-transition: all 0.3s ease-out;
                transition: all 0.3s ease-out;
                cursor: pointer;
                span {
                  -webkit-transition: opacity, color 0.3s ease-in;
                  transition: opacity, color 0.3s ease-in;
                  opacity: 0;
                  color: #ffffff;
                }
              }
              .info_restric {
                display: none;
                background: #ffffff;
                font-size: 0.7rem;
                padding: 0.5rem;
                padding-top: 0.95rem;
                border-radius: 7px;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 9;
                border: 2px solid #dddddd;
                box-shadow: -1px -1px 12px rgba(0, 0, 0, 0.15);
                min-width: 140px;
                width: 100%;
                color: #333;
                .close {
                  color: #aa1410;
                  position: absolute;
                  top: 5px;
                  right: 4px;
                  cursor: pointer;
                  font-size: 0.8rem;
                }
                &.isShow {
                  display: block;
                }
              }
            }
          }
        }

        hr {
          border-color: #dddddd;
        }
      }
    }
  }
}

.btn_closeaside {
  background: #e94242;
  border: 2px solid #e94242;
  padding: 0.15rem 0.4rem;
  color: #fff;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  cursor: pointer;
}
