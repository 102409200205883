.whatsapp-button {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 30px;
    right: 20px;
    background-color: #25bf484a;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 2000;
    transition: transform 0.3s ease;

    &:hover {
        transform: scale(1.1);
    }
}

.whatsapp-icon {
    width: 100%;
    height: 100%;
    padding: 10px;
}
